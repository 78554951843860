<template>
  <b-card-actions action-collapse title="Daftar Perbaikan & Pajak Kendaraan">
    <b-overlay :show="loading">
      <section v-if="canExport">
        <form-filter-export @onExport="exportPerbaikan" :type_export="['excel']">
        </form-filter-export>
      </section>
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-button
            v-b-modal.modal-tambah
            variant="primary"
            v-if="allowCreate() && myGudang"
            @click="add()"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            Perbaikan
          </b-button>
          <b-modal
            v-if="allowCreate() || allowUpdate()"
            v-model="showModal"
            id="modal-"
            ok-title="Tutup"
            ok-variant="secondary"
            ok-only
            centered
            title="Form "
          >
            <validation-observer ref="formperbaikan">
              <div class="d-flex">
                <feather-icon icon="PackageIcon" size="19" />
                <h6 class="ml-50 mb-2">Data Perbaikan & Pajak Kendaraan</h6>
              </div>
              <b-form>
                <b-row>
                  <!-- Tanggal Ajuan -->
                  <b-col cols="12">
                    <b-form-group label="Tanggal" label-for="v-tanggal">
                      <validation-provider
                        #default="{ errors }"
                        name="tanggal"
                        rules="required"
                      >
                        <b-form-input type="date" v-model="form.tanggal"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group label="Pilih Driver" label-for="v-id_driver">
                      <validation-provider
                        #default="{ errors }"
                        name="id_driver"
                        :rules="{ required: true }"
                      >
                        <b-form-select
                          :state="errors.length > 0 ? false : null"
                          v-model="form.id_driver"
                          :options="id_driver"
                          id="v-id_driver"
                          name="id_driver"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group label="Kendaraan" label-for="v-id_kendaraan">
                      <validation-provider
                        #default="{ errors }"
                        name="id_kendaraan"
                        :rules="{ required: true }"
                      >
                        <b-form-select
                          :state="errors.length > 0 ? false : null"
                          v-model="form.id_kendaraan"
                          :options="id_kendaraan"
                          id="v-id_kendaraan"
                          name="id_kendaraan"
                        />
                        <!-- :options="id_kendaraan" -->
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" v-if="isSPV">
                    <b-form-group label="Pilih Gudang" label-for="v-id_gudang">
                      <validation-provider
                        #default="{ errors }"
                        name="id_gudang"
                        :rules="{ required: true }"
                      >
                        <b-form-select
                          :state="errors.length > 0 ? false : null"
                          v-model="form.id_gudang"
                          :options="id_gudang"
                          id="v-id_gudang"
                          name="id_gudang"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Pilih Gudang"
                      label-for="v-id_gudang"
                      v-if="this.userData.level.id == 5"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="id_gudang"
                        :rules="{ required: true }"
                      >
                        <b-form-select
                          :state="errors.length > 0 ? false : null"
                          v-model="form.id_gudang"
                          :options="id_gudang"
                          id="v-id_gudang"
                          name="id_gudang"
                          disabled
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- keterangan -->
                  <b-col cols="12">
                    <b-form-group label="Keterangan" label-for="v-keterangan">
                      <b-form-textarea
                        v-model="form.keterangan"
                        id="v-keterangan"
                        placeholder="Isi Keterangan"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- submit and reset -->
                  <b-col cols="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      @click.prevent="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      Simpan
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                    >
                      Reset
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-modal>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group>
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12" v-if="isAdminGudang">
          <b-table
            striped
            small
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(urutan)="{ index }">
              {{ index + 1 }}
            </template>
            <template #cell(tanggal)="{ item }">
                  {{ humanDate(item.tanggal) }}
                </template>
            <template #cell(diberikan)="data">
              <b-badge :variant="iberi[1][data.value]">
                {{ iberi[0][data.value] }}
              </b-badge>
            </template>

            <template #cell(selesai)="data">
              <b-badge :variant="iterima[1][data.value]">
                {{ iterima[0][data.value] }}
              </b-badge>
            </template>
            <template #cell(status)="{item}">
              <b-badge :variant="getInfo(item).variant">
                {{ getInfo(item).status }}
              </b-badge>
            </template>
            <template #cell(total)="{ item }">
              <strong
                >Rp
                {{
                  formatRupiah(item.total)
                }}</strong
              >
            </template>
            <template #cell(actions)="row">
              <b-button
                v-b-tooltip.hover.right="'Detail'"
                size="sm"
                @click.prevent="$router.push(`/kendaraan/form-perbaikan/${row.item.id}`)"
                class=""
                variant="outline-success"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover.right="'Ubah'"
                v-if="allowUpdate() && row.item.selesai == 0"
                size="sm"
                @click="edit(row.item)"
                class=""
                variant="outline-info"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-if="allowDelete() && row.item.selesai == 0"
                v-b-tooltip.hover.right="'Hapus'"
                size="sm"
                @click="remove(row.item)"
                class=""
                variant="outline-danger"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12" v-else>
          <b-table
            striped
            small
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fieldsFinance"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(urutan)="{ index }">
              {{ index + 1 }}
            </template>
            <template #cell(total)="{ item }">
            <strong
              >Rp
              {{
                formatRupiah(item.total)
              }}</strong
            >
          </template>
          <template #cell(tanggal)="{ item }">
                  {{ humanDate(item.tanggal) }}
                </template>
            <template #cell(diberikan)="data">
              <b-badge :variant="iberi[1][data.value]">
                {{ iberi[0][data.value] }}
              </b-badge>
            </template>

            <template #cell(selesai)="data">
              <b-badge :variant="iterima[1][data.value]">
                {{ iterima[0][data.value] }}
              </b-badge>
            </template>
            <template #cell(status)="{item}">
              <b-badge :variant="getInfo(item).variant">
                {{ getInfo(item).status }}
              </b-badge>
            </template>
            <template #cell(actions)="row">
              <b-button
                  v-b-tooltip.hover.right="'Print / Cetak'"
                  size="sm"
                  @click.prevent="unduhbop(row.item)"
                  variant="outline-warning"
                >
                  <feather-icon icon="PrinterIcon"></feather-icon>
                </b-button>
              <b-button
                v-b-tooltip.hover.right="'Detail'"
                size="sm"
                @click.prevent="$router.push(`/kendaraan/form-perbaikan/${row.item.id}`)"
                class="mr-1"
                variant="outline-success"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover.right="'Ubah'"
                v-if="allowUpdate() && myGudang"
                size="sm"
                @click="edit(row.item)"
                class="mr-1"
                variant="outline-info"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-if="allowDelete() && myGudang"
                v-b-tooltip.hover.right="'Hapus'"
                size="sm"
                @click="remove(row.item)"
                class="mr-1"
                variant="outline-danger"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </b-card-actions>
</template>

<script>
import FormFilterExport from '@core/components/form-filter-export/FormFilterExport.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import service from '@/services'
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BForm,
  BTable,
  VBTooltip,
  BFormDatepicker,
  BFormTimepicker,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BOverlay,
  BCardText,
} from "bootstrap-vue";

export default {
  components: {
    FormFilterExport,
    BForm,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    VBTooltip,
    BFormTimepicker,
    BModal,
    BOverlay,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BCardText,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  watch: {
    "form.id_driver"(val) {
      if (val) {
        const currentDriver = this.id_driver.find(
          (gudang) => gudang.driver?.id == val
        );
        if (currentDriver) {
          this.form.id_kendaraan = currentDriver.id_driver.kendaraan.id;
          // this.form.blok_id = currentDriver.blok.id
        }
      }
    },
  },
  data() {
    return {
      loading: false,
      statusOptions: [
        {
          value: "all",
          text: "Semua",
        },
      ],
      selectedStatus: "all",
      showModal: false,
      required,
      password,
      email,
      confirmed,
      form: {
        id_driver: null,
        id_kendaraan: null,
        id_gudang: null,
        id_user_gudang: null,
        id_kas: null,
        tanggal: new Date(),
        keterangan: null,
      },
      id_driver: [],
      id_kendaraan: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      canExport: false,
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: true,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detailmodal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      iberi: [
        {
          0: "Belum",
          1: "Setuju",
          null: "belum",
        },
        {
          0: "light-danger",
          1: "light-success",
          null: "light-danger",
        },
      ],
      iterima: [
        {
          0: "Belum",
          1: "Terima",
          null: "belum",
        },
        {
          0: "light-danger",
          1: "light-success",
          null: "light-danger",
        },
      ],
      fields: [
        {
          key: "urutan",
          label: "#",
        },
        {
          key: "no",
          label: "No",
        },
        { key: "tanggal", label: "Tgl", sortable: true },
        { key: "gudang.nama_gudang", label: "Gudang" },
        { key: "pembuat.nama_lengkap", label: "Admin" },
        { key: "driver.nama_lengkap", label: "driver" },
        { key: "kendaraan.nama_kendaraan", label: "kendaraan" },
        { key: "keterangan", label: "Keterangan" },
        { key: "total", label: "total" },
        { key: "status", label: "Status"},
        { key: "actions", label: "Aksi" },
      ],
      fieldsFinance: [
        {
          key: "urutan",
          label: "#",
        },
        {
          key: "no",
          label: "No",
        },
        { key: "tanggal", label: "Tgl Ajuan", sortable: true },
        { key: "gudang.nama_gudang", label: "Gudang" },
        { key: "keterangan", label: "Keterangan" },
        { key: "total", label: "total" },
        { key: "kas.nama", label: "Kas" },
        { key: "status", label: "Status"},
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      status: [
        {
          1: "Belum",
          2: "Approve SPV",
          3: "Approve Gudang",
          4: "Sudah Ambil",
        },
        {
          1: "light-danger",
          2: "light-info",
          3: "light-warning",
          4: "light-success",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  async mounted() {
    this.getid_kendaraan();
    this.getid_driver();
    await this.getData();
    if(this.isFinance) {
      this.sortBy = ''
      this.sortDesc = true
    }

    await this.checkPermissionExport()
  },
  methods: {
    async exportPerbaikan({tgl_awal, tgl_akhir}) {
      if(!tgl_awal || !tgl_akhir) {
        this.displayError({
          message: 'Harap isi tanggal awal dan akhir!'
        })
        return false
      }

      // validate type_export pdf || excel
      const params = {
        tgl_awal,
        tgl_akhir
      }

      try {
        this.loading = true
        const response = await this.printPerbaikan(params)
        this.loading = false
        await window.open(response)
        setTimeout(async () => {
          // delete selected file
          const arrFile = response.split('/')
          const filename = arrFile[ arrFile.length - 1 ]

          await this.clearExportedFile(filename)
        }, 1000)
      }
      catch(e) {
        console.error(e)
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    async printPerbaikan(params = {}) {
      try {
        const config = {
          method: 'get',
          url: '/excel-rekap-perbaikan-kendaraan',
          params,
          headers: {
            Authorization: `bearer ${localStorage.token}`
          }
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      }
      catch(e) {
        return Promise.reject(e)
      }
    },
    async checkPermissionExport() {
      const menu = await this.currentMenu(this.$route.path)
      const params = {
          level_id: this.user.level.id
      }
      if(menu) {
          params.menu_id = menu.id
      }
      const listStatus = await this.$store.dispatch('statusrole/getData', params)
      const canExport = listStatus.some(status => status.data_status == 1)
      this.canExport = canExport
    },
    getInfo(item) {
      const result = {
        variant: 'light-primary',
        kode: 1,
        status: 'Belum Selesai'
      }
      if(item.selesai == 1 && item.gm_approve == 0) {
        result.variant = 'light-danger'
        result.kode = 2
        result.status = 'Menunggu persetujuan GM'
      }
      else if(item.selesai == 1 && item.gm_approve == 1 && item.tolak == 0 && item.id_akun == null) {
        result.variant = 'light-success'
        result.kode = 3
        result.status = 'Disetujui GM'
      }
      else if(item.selesai == 1 && item.gm_approve == 2 && item.tolak == 1 && item.id_akun == null) {
        result.variant = 'light-secondary'
        result.kode = 4
        result.status = 'Ditolak GM'
      }
      else if(item.selesai == 1 && item.tolak == 0 && item.id_akun == null) {
        result.variant = 'light-danger'
        result.kode = 5
        result.status = 'Pending'
      }
      else if(item.selesai == 1 && item.tolak == 1) {
        result.variant = 'dark'
        result.kode = 6
        result.status = 'Ditolak Finance'
      }
      else if(item.selesai == 1 && item.id_akun > 0) {
        result.variant = 'success'
        result.kode = 7
        result.status = 'Selesai'
      }

      return result
    },
    async unduhbop(item) {
      this.loading = true
      const response = await this.$store.dispatch("perbaikan/printAjuan", item.id);
      this.loading = false
      window.open(URL.createObjectURL(response))
    },
    async getStatus() {
      const currentMenu = await this.currentMenu();
      const params = {
        menu_id: currentMenu.id,
        level_id: this.user.level.id,
      };
      const statusAjuan = await this.$store.dispatch("statusrole/getData", params);
      statusAjuan.map((status) => {
        this.statusOptions.push({
          value: status.data_status,
          text: status.nama_status,
        });
      });
    },
    // downloadAttachment(ajuan_id) {
    //   window.open(`/pengambilan-barang/download-attachment/${ajuan_id}`, "_blank");
    // },
    setStatus(status) {
      let result = {
        color: "primary",
        text: "",
      };
      switch (status) {
        case null:
        case 1:
          result = {
            color: "danger",
            text: "PENDING",
          };
          break;
        case 2:
          result = {
            color: "info",
            text: "DISETUJUI SPV",
          };
          break;
        case 3:
          result = {
            color: "dark",
            text: "DISETUJUI GUDANG",
          };
          break;
        case 4:
          result = {
            color: "success",
            text: "SELESAI",
          };
          break;
      }

      return result;
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    edit(item) {
      this.form = item;
      if (this.form.sales && !this.isAdminGudang)
        this.form.id_gudang = this.form.sales.id;
      this.id = item.id;
      this.showModal = true;
    },
    add() {
      this.resetForm();
      this.showModal = true;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: "Data ajuan barang ini akan dihapus",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(() => {
        item.fungsi = 1; // soft delete
        this.$store
          .dispatch("perbaikan/save", [item])
          .then(() => {
            this.getData();
            this.displaySuccess({
              message: "Ajuan berhasil dihapus",
            });
            this.resetForm();
          })
          .catch((e) => {
            this.displayError(e);
            return false;
          });
      });
    },
    resetForm() {
      this.id = null;
      this.form.id = null;
      this.form.tanggal = this.getCurrentDate();
      this.form.keterangan = null;
      if (!this.isAdminGudang) {
        this.form.id_gudang = null;
      }
    },
    submit() {
      this.$refs.formperbaikan.validate().then(async (success) => {
        if (success) {
          if (this.isAdminGudang) {
            this.form.id_user_gudang = this.user.karyawan.id;
          }
          if (this.id) {
            this.form.id = this.id;
          }
          this.form.id_gudang = this.myGudang.id;
          // this.form.id_kendaraan = this.id_driver.kendaraan.id;
          if (!this.form.keterangan) {
            this.form.keterangan = "-";
          }
          const payload = this.form;
          try {
            this.label = "Loading...";
            const ajuan = await this.$store.dispatch("perbaikan/save", [payload]);
            this.label = "Submit";
            if (this.id) {
              this.getData();
              this.displaySuccess({
                message: "Ajuan berhasil disimpan",
              });
            } else {
              this.displaySuccess({
                message: "Ajuan berhasil disimpan",
              });
              this.$router.push(`/kendaraan/form-perbaikan/${ajuan.id}`);
            }
          } catch (e) {
            console.error(e);
            this.displayError(e);
            return false;
          }
        }
      });
    },
    async getData() {
      // set payload if sales itself, filter per sales itself
      let params = this.isAdminGudang
        ? { id_gudang: this.myGudang.id, order: "desc" }
        : { order: "desc" };
      this.loading = true;
      const ajuans = await this.$store.dispatch("perbaikan/getData", params);
      ajuans.map(ajuan => ajuan.info = this.getInfo(ajuan))
      
      const belumAjuan = ajuans.filter(ajuan => ajuan.info.kode == 1)
      const pendingAjuan = ajuans.filter(ajuan => ajuan.info.kode == 2)
      const completeAjuan = ajuans.filter(ajuan => ajuan.info.kode == 4)
      const otherAjuan = ajuans.filter(ajuan => ajuan.info.kode != 1 && ajuan.info.kode != 2 && ajuan.info.kode != 4)
      this.items = [...pendingAjuan, ...completeAjuan, ...belumAjuan, ...otherAjuan]
      if(this.isAdminGudang) {
        this.items = [ ...belumAjuan, ...pendingAjuan, ...completeAjuan, ...otherAjuan]
      }
      else {
        this.items = [ ...pendingAjuan, ...completeAjuan, ...otherAjuan, ...belumAjuan]
      }
      this.loading = false;
      this.items = this.items;
      this.totalRows = ajuans.length;
    },
    getid_gudang() {
      this.$store
        .dispatch("karyawan/getData", { jabatan_id: 5 })
        .then(() => {
          let karyawan = JSON.parse(JSON.stringify(this.$store.state.karyawan.datas));
          karyawan.map((item) => {
            item.value = item.id;
            item.text = item.nama_gudang;
          });

          this.id_gudang = karyawan;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getid_driver() {
      this.$store
        .dispatch("gudang/getData", { jenis: 2 })
        .then(() => {
          let gudang = JSON.parse(JSON.stringify(this.$store.state.gudang.datas));
          gudang = gudang.filter(gd => gd.driver != null)
          gudang.map((item) => {
            item.value = item.driver?.id;
            item.text = item.driver ? item.driver.nama_lengkap : 'Nama Kosong';
          });
          this.id_driver = gudang;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getid_kendaraan() {
      this.$store
        .dispatch("kendaraan/getData", {})
        .then(() => {
          let ken = JSON.parse(JSON.stringify(this.$store.state.kendaraan.datas));
          ken.map((item) => {
            item.value = item.id;
            item.text = item.nama_kendaraan;
          });
          this.id_kendaraan = ken;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getsalesName(data) {
      return data.sales ? data.sales.nama_lengkap : "-";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13;
    },
  },
};
</script>
